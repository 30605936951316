import React from 'react';

const ImageSection = () => {
    return (
        <div className={`flex flex-row justify-center`}>
            <img src={`/images/masaFull.png`} alt={`Full`} className={`w-full h-auto`}/>
        </div>
    );
};

export default ImageSection;