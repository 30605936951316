import React from 'react';

const QuestionAnswer = () => {
    return (
        <div className={`bg-customBlack`} id={`intreaba`}>
            <div className={`desktop:hidden`}>
                <div className={`relative`}>
                    <img className={`desktop:hidden w-1/2 left-0 top-[5%] absolute`} src='/images/Frunza5.svg'
                         alt={`funza`}/>
                    <h5 className={`text-h5 text-center text-white py-16 desktop:px-16  mobile:px-14 relative z-10`}>Întreabă-ne, noi îți vom
                        răspunde cu drag!</h5>
                    <div className={` flex flex-col space-y-20 relative z-10 mobile:px-14`}>
                        <div className={`flex flex-col text-white items-center justify-center text-center`}>
                            <img src={`/images/tel.svg`} alt={`telephone`} className={`w-[68px] py-4`}/>
                            <a className={`cursor-pointer`} href={`tel:0232730 392`}><h6
                                className={`text-ptSerif28Bold`}>(0232) 730 392</h6></a>
                            <span className={`text-nunito18_regular`}>( apel telefonic )</span>
                            <p className={`text-nunito16_regular pt-8 text-center max-w-[380px] mx-auto`}>În cadrul
                                secțiunii
                                noastre, te invităm să pătrunzi în universul
                                vinicol Cotnari prin intermediul
                                apelurilor telefonice. Programul nostru cu publicul este gândit să se potrivească
                                rutinei tale:
                                de
                                luni până vineri, între orele <span
                                    className={`text-nunito18_bold`}>08:00-10:00 și 13:00-15:00.</span></p>
                        </div>
                        <div className={`flex flex-col text-white items-center justify-center text-center`}>
                            <img src={`/images/message.svg`} alt={`whatsapp`} className={`w-[68px] py-4`}/>
                            <a className={`text-white cursor-pointer`} href={`tel:0741 290 940`}><h6
                                className={`text-ptSerif28Bold`}>0741 290 940</h6></a>
                            <span className={`text-nunito18_regular`}>( whatsapp )</span>
                            <p className={`text-nunito16_regular pt-8 text-center max-w-[380px] mx-auto`}>Pentru un
                                răspuns
                                rapid și eficient, poți folosi și WhatsApp, unde Andrei este disponibil <span
                                    className={`text-nunito18_bold`}> 24/24 </span>
                                pentru a prelua și a direcționa întrebările tale către noi.</p>
                        </div>
                        <div className={`flex flex-col text-white items-center justify-center text-center`}>
                            <img src={`/images/msgtxt.svg`} alt={`msg`} className={`w-[68px] py-1`}/>
                            <a className={`cursor-pointer text-center mx-auto max-w-[400px]`}
                               href={`mailto:cramatransparenta@cramelecotnari.ro`}><h6
                                className={`text-ptSerif21Bold`}>cramatransparenta<br/>@cramelecotnari.ro</h6></a>
                            <span className={`text-nunito18_regular`}>( email )</span>
                            <p className={`text-nunito16_regular pt-8 text-center pb-16 max-w-[380px] mx-auto`}>
                                Îți oferim și opțiunea de a ne contacta prin email unde îți vom răspunde cu aceeași
                                promptitudine și atenție la fiecare întrebare primită.</p>
                        </div>
                    </div>
                    <img className={`desktop:hidden w-1/2 bottom-0 right-0 absolute`} src='/images/Frunza6.svg'
                         alt={`funza`}/>
                </div>
                <div>
                    <img src={`/images/sticle.png`} alt={`sticle`} className={`w-[100%] h-auto`}/>
                </div>
            </div>

            <div className={`mobile:hidden flex flex-row`}>
                <div className={`basis-1/3`}>
                    <img src={`/images/sticle2.png`} alt={`sticle`} className={`w-full h-full object-cover`}/>
                </div>
                <div className={`py-16 space-y-10 basis-1/2 items-center flex flex-col justify-center relative`}>
                    <div className={`relative z-10 py-16 space-y-10`}>
                        <h5 className={`text-h5 text-left px-16 text-white`}>Întreabă-ne, noi îți vom <br/> răspunde cu
                            drag!</h5>
                        <div className={`flex flex-row space-x-10 px-16 max-w-[850px] mx-auto`}>
                            <div className={`w-[200px]`}>
                                <img src={`/images/tel.svg`} alt={`telephone`} className={`w-[68px] `}/>
                            </div>
                            <div className={`text-white space-y-2 `}>
                                <div className={`flex flex-row space-x-2`}>
                                    <a className={`cursor-pointer`} href={`tel:0232730392`}><h6
                                        className={`text-ptSerif28Bold`}>(0232) 730 392</h6>
                                    </a>
                                    <span className={`text-nunito18_regular`}>( apel telefonic )</span>
                                </div>
                                <p className={`text-nunito16_regular`}>În cadrul
                                    secțiunii
                                    noastre, te invităm să pătrunzi în universul
                                    vinicol Cotnari prin intermediul
                                    apelurilor telefonice. Programul nostru cu publicul este gândit să se potrivească
                                    rutinei tale:
                                    de
                                    luni până vineri, între orele <span
                                        className={`text-nunito18_bold`}>08:00-10:00 și 13:00-15:00.</span></p>
                            </div>
                        </div>
                        <div className={`flex flex-row space-x-10 px-16 max-w-[850px] mx-auto`}>
                            <div className={`w-[120px]`}>
                                <img src={`/images/message.svg`} alt={`whatsapp`} className={`w-[68px]`}/>
                            </div>
                            <div className={`text-white space-y-2`}>
                                <div className={`flex flex-row space-x-2 `}>
                                    <a className={`cursor-pointer`} href={`tel:0741 290 940`}><h6
                                        className={`text-ptSerif28Bold`}>0741 290 940</h6></a>
                                    <span className={`text-nunito18_regular`}>( whatsapp )</span>
                                </div>
                                <p className={`text-nunito16_regular`}>Pentru un
                                    răspuns
                                    rapid și eficient, poți folosi și WhatsApp, unde Andrei este disponibil <span
                                        className={`text-nunito18_bold`}> 24/24 </span>
                                    pentru a prelua și a direcționa întrebările tale către noi.</p>
                            </div>
                        </div>
                        <div className={`flex flex-row space-x-10  items-center px-16 max-w-[850px] mx-auto`}>
                            <div className={`w-[120px] `}>
                                <img src={`/images/msgtxt.svg`} alt={`msg`} className={`w-[68px]`}/>
                            </div>
                            <div className={`text-white space-y-2`}>
                                <div className={`flex flex-row space-x-2 items-center`}>
                                    <a className={`cursor-pointer`}
                                       href={`mailto:cramatransparenta@cramelecotnari.ro`}><h6
                                        className={`text-ptSerif21Bold`}>cramatransparenta@cramelecotnari.ro</h6></a>
                                    <span className={`text-nunito18_regular`}>( email )</span>
                                </div>
                                <p className={`text-nunito16_regular`}>Îți
                                    oferim și
                                    opțiunea de a ne contacta prin email unde îți vom răspunde cu aceeași promptitudine
                                    și
                                    atenție fiecărei pentru fiecare întrebare primită.</p>
                            </div>
                        </div>
                    </div>
                    <img src={`/images/Frunza7.svg`} alt={`frunza7`} className={`w-2/3 absolute left-0 bottom-0`}/>
                </div>
            </div>

        </div>
    );
};

export default QuestionAnswer;