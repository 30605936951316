import React from 'react';

const FooterLink = ({ href, text }) => {
    const handleClick = (e) => {
        e.preventDefault();
        const element = document.getElementById(href.slice(1));
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <a href={href} onClick={handleClick} className="uppercase hover:text-butterCup text-nunito14_regular">{text}</a>
    );
};

const Footer = () => {
    return (
        <div className="footer flex flex-col desktop:flex-row bg-customBlack text-white p-10 desktop:space-x-20 justify-center">
            {/* Col 1 */}
            <div className="col-1 text-center space-y-2 py-4">
                {/* Contact Info */}
                <h6 className="text-h6 py-2">Contact</h6>
                <div className="space-y-2">
                    <p className="uppercase text-text_bold text-alto">S.C. CRAMELE COTNARI S.A.</p>
                    <p className="uppercase text-nunito14_regular text-alto">RO40321026 — J22/3498/2018</p>
                    <p className="text-nunito14_regular text-alto">Com. Cotnari CORP C28, jud. Iași</p>
                    <p className="text-nunito14_regular text-alto">Cod poștal 707120</p>
                </div>
                {/* Email and Phone */}
                <div className="space-y-2 py-6">
                    <div className="flex flex-row space-x-2 justify-center">
                        <span className="text-nunito14_bold text-white">E-Mail:</span>
                        <a href={`mailto:cramatransparenta@cramelecotnari.ro`} className="text-nunito14_regular text-alto">cramatransparenta@cramelecotnari.ro<br/>(timp mediu de răspuns - 24h)</a>
                    </div>
                    <div className="flex flex-col space-x-2 justify-center">
                        L-V: 07:00-15:30
                    </div>
                    {/* Design Credit */}
                    <div className="flex flex-row justify-center">
                        <span className="text-alto text-nunito12_regular justify-center">Design @ b1studio.ro</span>
                    </div>
                </div>
            </div>

            {/* Col 2 */}
            <div className="col-2 text-center space-y-7 py-4">
                {/* Logo */}
                <div className="flex flex-row justify-center">
                    <img src="/images/logo_footer_cotnari.svg" alt="cotnari-logo"/>
                </div>
                {/* Social Media */}
                <div className="social-media flex flex-row cursor-pointer justify-center">
                    <a href="https://www.facebook.com/cramelecotnari/"><img src="/images/fb.svg" alt="facebook"/></a>
                    <a href="https://www.instagram.com/cotnari.romania/"><img src="/images/insta.svg" alt="instagram"/></a>
                </div>
                {/* Payment Logos */}
                <div className="payments flex flex-row justify-center">
                    <img src="/images/logo-mastercard.png.svg" alt="mastercard"/>
                    <img src="/images/logo-visa.png.svg" alt="visa"/>
                    <img src="/images/logo-plationline.png.svg" alt="plati-online"/>
                </div>
                {/* Legal Disputes */}
                <div className="litigii flex flex-col justify-center space-y-2">
                    <div className="w-full h-[40px] flex justify-center">
                        <img src="/images/LinkSOL.png" alt="sol"/>
                    </div>
                    <div className="w-full h-[40px] flex justify-center">
                        <img src="/images/LinkSal.png" alt="sal"/>
                    </div>
                </div>
            </div>

            {/* Col 3 */}
            <div className="col-3 text-center space-y-6 py-4">
                {/* Menu */}
                <h6 className="text-h6 text-white py-2">Meniu</h6>
                <div className="cursor-pointer flex flex-col space-y-2">
                    {/* Navigation Links */}
                    <FooterLink href="#poveste" text="Poveste"/>
                    <FooterLink href="#prezentare" text="Prezentare"/>
                    <FooterLink href="#intreaba" text="Intreaba"/>
                    <FooterLink href="#vezi-crama" text="Vezi Crama Live"/>
                    <FooterLink href="#viziteaza-ne" text="Viziteaza-ne"/>
                    {/* Discover Wines */}
                    <div className="py-6">
                        <a href="https://www.cramelecotnari.ro/" className="text-white bg-butterCup p-4 w-full text-nunito14_semibold">Descoperă Vinurile Noastre</a>
                    </div>
                    {/* Copyright */}
                    <p className="text-alto text-nunito12_regular mt-8">Copyright © S.C. Cramele Cotnari S.A.</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
