import React from "react";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import PovesteSiTraditie from "../components/PovesteSiTraditie";
import PrezentareVideo from "../components/PrezentareVideo";
import QuestionAnswer from "../components/QuestionAnswer";
import StreamVideo from "../components/StreamVideo";
import ViziteazaCrama from "../components/ViziteazaCrama";
import ImageSection from "../components/ImageSection";

const HomePage = () => {
    return (
        <div className={`bg-customBlack`}>
            <div className={`container`}>
                <Banner/>
                <PovesteSiTraditie/>
                <PrezentareVideo/>
                <QuestionAnswer/>
                <StreamVideo/>
                <ViziteazaCrama/>
                {/*<ImageSection/>*/}
                <Footer/>
            </div>
        </div>
    );
};

export default HomePage;
