import React from 'react';
import { useNavigate } from "react-router-dom";

const Header = ({ className }) => {
    const navigate = useNavigate();

    const handleClick = (e, url) => {
        e.preventDefault();
        if (url.startsWith('#')) {
            const element = document.getElementById(url.slice(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            navigate(url);
        }
    };

    const links = [
        { href: "#poveste", text: "Poveste" },
        { href: "#prezentare", text: "Prezentare" },
        { href: "#intreaba", text: "Intreaba" },
        { href: "#vezi-crama", text: "Vezi crama live" },
        { href: "#viziteaza-ne", text: "Viziteaza-ne" }
    ];

    return (
        <div className={className}>
            <div className="flex flex-row justify-between">
                <img src="/images/logo.svg" alt="logo" className="logo mobile:mx-auto left-0 right-0 mt-12 desktop:w-[291px] desktop:h-[81px]" />
                <div className="links flex flex-row space-x-8 mt-24 justify-self-end mobile:hidden">
                    {links.map((link, index) => (
                        <a key={index} href={link.href} onClick={(e) => handleClick(e, link.href)} className="text-white text-a uppercase">{link.text}</a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Header;
