import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import router from "./routers";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <RouterProvider router={router}></RouterProvider>
);
reportWebVitals();
