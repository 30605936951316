import React from 'react';

const PovesteSiTraditie = () => {
    return (
        <div className={`py-[95px] relative`} style={{backgroundColor: `#101200`}} id={`poveste`}>
            <img className={`desktop:hidden w-1/2 right-0 top-0 absolute`} src='/images/Frunza1.svg' alt={`funza`}/>
            <img className={`mobile:hidden w-1/2 bottom-0 right-0 absolute`} src='/images/Frunza3.svg' alt={`funza`}/>
            <div className={`relative z-10 max-w-[900px] mx-auto`}>
                <h5 className={`text-white text-center text-h5 pb-[65px]`}>Povestea transparentă</h5>
                <div className={`content text-white text-center space-y-8 mobile:px-14`}>
                    <p className={`text-nunito16_regular`}>Încă din anul 1948 își are existența Crama
                        Cotnari, iar dragostea localnicilor pentru creșterea și
                        rodul viței de vie ne-a învățat să prețuim fiecare boabă purtătoare de soare și să împărtășim cu
                        ceilalți bucuria vieții.</p>

                    <p className={`text-nunito16_regular`}>Drumul vinului este lung, dar plin de
                        recompense. Avem permanent grijă de cele peste 1.750 de hectare
                        cu plantații de vie cu soiuri de Grasă de Cotnari, Tămâioasă Românească, Frâncușă și Fetească
                        Albă.
                        Podgoria este adăpostită de vânturi și de curenții locali reci, datorită fenomenului de
                        phoenizare a
                        maselor de aer, ce determină un proces de încălzire.</p>

                    <p className={`text-nunito16_regular`}>În vinoteca noastră, organizată pe
                        criterii științifice, păstrăm cele mai reușite colecții, începând
                        din anul 1956. Locul unde în urma unei vizite se poate simți urma tradiției și a obiceiurilor
                        străvechi ale vinului, sala de degustare în stil tradițional te poartă în vremurile cele de mult
                        apuse.</p>

                    <p className={`text-nunito16_regular`}>Podgoria Cotnari face parte din Regiunea
                        viticolă a Podișului Moldovei, fiind situată în cea mai mare
                        parte a sa pe Coasta Cotnari – Hârlău, un segment important din marea Coastă Moldavă.</p>

                    <p className={`text-nunito16_regular`}>Noi suntem singura cramă din România
                        ce produce vinuri din soiuri pure românești!</p>
                </div>
            </div>
            <img className={`desktop:hidden w-1/2 left-0 bottom-0 absolute`} src='/images/Frunza2.svg' alt={`funza`}/>
            <img className={`mobile:hidden w-1/2 bottom-0 absolute`} src='/images/Frunza4.svg' alt={`funza`}/>
        </div>
    );
};

export default PovesteSiTraditie;