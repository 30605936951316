import React from 'react';
import Header from "./Header";

const Banner = () => {
    return (
        <div className="banner relative">
            <Header className="absolute inset-x-0 px-20"/>
            <img className="mobile:hidden w-full" src="/images/bannerDesktop.png" alt='banner'/>
            <img className="desktop:hidden w-full h-[800px] object-cover" src="/images/bannerMob.png" alt='banner'/>
            <h4 className="text-white text-center mobile:text-h4 desktop:text-h1 top-[45%] absolute left-0 right-0 mx-auto ">
                CRAMA <br /> TRANSPARENTĂ
            </h4>
        </div>
    );
};

export default Banner;
