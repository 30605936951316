import React from 'react';

const ViziteazaCrama = () => {
    return (
        <div id={`viziteaza-ne`} className={`bg-customBlack`}>
            <div className={`flex mobile:flex-col desktop:flex-row justify-between relative`}>
                <div className={`desktop:order-last`}>
                    <img className={`desktop:hidden`} src={`/images/femeie.png`} alt={`femeie`}/>
                    <img className={`mobile:hidden w-full h-full object-cover`} src={`/images/femeie2.png`}
                         alt={`femeie`}/>
                    {/*<img src={`/images/FrunzaViziteaza.svg`} className={`absolute top-5 right-96`}*/}
                    {/*     alt={`vie`}/>*/}
                </div>

                <div className={`text-white relative basis-1/2 items-center flex flex-col justify-center`}>
                <img src={`/images/vie.svg`} className={`desktop:hidden absolute top-0`} alt={`vie`}/>
                    <div className={`relative z-10 mobile:px-14 px-20`}>
                        <h5 className={`text-ptSerif32Bold mobile:text-center py-16 mobile:mx-auto mobile:max-w-[500px] desktop:pl-10`}>Vizitează-ne
                            și descoperă Crama Transparentă Cotnari</h5>
                        <div>
                            <p className={`text-nunito18_regular text-white mobile:text-center mobile:mx-auto mobile:max-w-[500px] desktop:px-10`}>Pasionat
                                de vin? Crama Cotnari îți oferă o călătorie inedită în lumea vinului, îmbinând
                                istoria cu savoarea. Explorează cramele din Cotnari, pășind pe urmele moștenirii vinicole
                                românești. Un ghid pasionat te va introduce în tainele producției vinului, dezvăluind
                                secretele din spatele vinurilor Cotnari. Admiră vechile beciuri și află cum strugurii se
                                transformă în licori aromate. Experiența continuă cu o degustare a vinurilor premiate
                                Cotnari. De la nuanțele delicate de Fetească Albă, la aromele bogate de Grasă și Tămâioasă Românească, fiecare sortiment își va dezvălui povestea gustativă.
                                Alege pachetul de degustare potrivit preferințelor tale, de la opțiuni simple, la degustări complexe cu acompaniament culinar.
                                Rezervă-ți vizita la Crama Cotnari, accesând link-ul de mai jos și bucură-te de o poveste
                                a vinului care se scrie de secole.
                            </p>
                            <div className={`mobile:text-center my-16 desktop:pl-10`}>
                                <a href={`https://www.cramelecotnari.ro/wineries`} className={`text-white bg-butterCup p-4 w-full text-nunito14_semibold`}>Vizitează
                                    cramele din Cotnari</a>
                            </div>
                        </div>
                    </div>
                    <img src={`/images/vie2.svg`} className={`desktop:hidden absolute bottom-0`}
                         alt={`vie`}/>
                </div>
            </div>
        </div>
    );
};

export default ViziteazaCrama;