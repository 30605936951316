import React, { useState, useRef } from 'react';

const PrezentareVideo = () => {
    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null);

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setPlaying(true);
        }
    };

    const isMobile = window.innerWidth <= 768;

    return (
        <div style={{position: 'relative', width: '100%', height: 'auto'}} id="prezentare">
            <iframe  className={`w-full desktop:h-[85vh] mobile:h-[35vh]`}  src="https://www.youtube.com/embed/QjCJ5Ynl-Lg?si=eXppMNiNqieFBIVK&amp;controls=0" title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            {/*<video className={`w-full`} controls={false}*/}
            {/*       poster={isMobile ? `/images/videoPoster.png` : `/images/videoPoster2.png`} ref={videoRef}*/}
            {/*       onPlay={() => setPlaying(true)} onPause={() => setPlaying(false)}>*/}
            {/*    <source src={'/video/sample.mp4'}/>*/}
            {/*</video>*/}
            {/*{!playing && (*/}
            {/*    <button onClick={playVideo} style={{*/}
            {/*        position: 'absolute',*/}
            {/*        top: '50%',*/}
            {/*        left: '50%',*/}
            {/*        transform: 'translate(-50%, -50%)',*/}
            {/*        zIndex: '1'*/}
            {/*    }}>*/}
            {/*        <img src="/images/play.svg" alt="Play Button" style={{width: '100px', height: '100px'}}/>*/}
            {/*    </button>*/}
            {/*)}*/}
        </div>
    );
};

export default PrezentareVideo;
