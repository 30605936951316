import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StreamVideo = () => {
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <img src="/images/right.svg" alt="Next"/>
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <img src="/images/left.svg" alt="Prev"/>
            </div>
        );
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className={`bg-white px-10 mobile:py-16 desktop:py-40 space-y-6 relative`} id={`vezi-crama`}>
            <img src={`images/frunzaalba3.svg`} alt={`frunzaAlba`}
                 className={`mobile:hidden absolute top-0 left-0`}/>
            <div className={`flex flex-row mobile:justify-center desktop:justify-between desktop:gap-0`}>
            <img className={`mobile:hidden`} src={`/images/line1.svg`} alt={`line`}/><h5
            className={`text-ptSerif32Bold text-customBlack text-center `}>Streaming live</h5><img
                className={`mobile:hidden`}  src={`/images/line2.svg`} alt={`line`}/>
            </div>
            <div className={`text-center relative z-10`}>
                <p className={`text-nunito16_regular text-customBlack max-w-[850px] mx-auto`}>Descoperă viața din
                    spatele scenei și bucură-te
                    de o experiență autentică accesând secțiunea noastră
                    de streaming unde poți urmări în timp real imagini live din cramele noastre,
                    oferindu-ți o perspectivă captivantă asupra procesului nostru de producție a vinului.</p>
            </div>

            <div className="slider-container cursor-pointer space-x-2 desktop:px-20 relative z-10">
                <Slider {...settings}>
                    <div className={`p-5`}>
                        <div className={`border-2 border-butterCup  iframe p-2`}>
                            <iframe className={`w-full`} width="560" height="315" src="https://rtsp.me/embed/62Nak8dn/"
                                    title={`Camera 1 - Platforma cisterne inox`}
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className={`p-5`}>
                        <div className={`border-2 border-butterCup  iframe p-2`}>
                            <iframe className={`w-full`} width="560" height="315" src="https://rtsp.me/embed/5FYE4ykn/"
                                    title={`Camera 2 - Sala maturare butoaie stejar`}
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className={`p-5`}>
                        <div className={`border-2 border-butterCup  iframe p-2`}>
                            <iframe className={`w-full`} width="560" height="315" src="https://rtsp.me/embed/yz8z6d7h/"
                                    title={`Camera 3 - Sala spumante metoda tradițional`}
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </Slider>
            </div>
            <img src={`images/frunzaAlba.svg`} alt={`frunzaAlba`}
                 className={`desktop:hidden left-0 absolute bottom-0`}/>
            <img src={`images/frunzaalba2.svg`} alt={`frunzaAlba`}
                 className={`mobile:hidden absolute bottom-0 right-0`}/>
        </div>
    );
};

export default StreamVideo;